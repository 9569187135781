import React, { useState, useEffect } from "react";
import Left from "../Assets/button_left.svg";
import Rigth from "../Assets/button_rigth.svg";
import Mayor from "../Assets/mayor_que.svg";
import styled from "styled-components";
import { Link } from "gatsby";
const StyledCardKnow = styled.div`
  border: 1px solid var(--strong-blue);
  border-radius: 16px;
  padding: 20px 10px;
  width: 230px;
  h1 {
    font-family: "lato_bold";
    font-size: 18px;
    color: var(--strong-blue);
  }
  .enlace {
    display: flex;
    margin-top: 20px;
    a {
      cursor: pointer;
      display: flex;
      align-items: center;
      background-color: var(--white);
      border-radius: 37px;
      border: 1px solid var(--strong-blue);
      font-family: "lato_regular";
      color: var(--strong-blue);
      font-size: 18px;
      padding: 10px;
      width: 150px;
    }
    img {
      margin-left: 10px;
      height: 15px;
      margin-top: 1px;
    }
  }
  @media (min-width: 800px) {
    width: 280px;
    .enlace {
        margin-top: 20px;
      a {
        padding: 10px;
      }
    }
  }
  @media (min-width: 1200px) {
    width: 350px;
    height: 200px;
    padding: 20px;
    margin: 10px;
    h1 {
      font-size: 25px;
      height: 70px;
    }
    .enlace {
        margin-top: 20px;
      a {
        padding: 20px;
        width: 184px;
        font-size: 25px;
        height: 62px;
      }
    }
  }
`;
const StyledSliderKnow = styled.div`
  padding: 60px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .head_title {
    display: flex;
    justify-content: center;
    h1 {
      font-family: "lato_regular";
      font-size: 16px;
      color: var(--strong-blue);
      max-width: 100%;
      text-align: center;
      span {
        font-family: "lato_italic";
        font-style: italic;
      }
    }
  }
  .container_slider {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      border: none;
      background: transparent;
      cursor: pointer;
    }
    .button_left {
      margin-right: 20px;
    }
    .button_rigth {
      margin-left: 20px;
    }
    .two_more_slides {
      display: none;
    }
  }

  @media (min-width: 600px) {
    .head_title {
      h1 {
        max-width: 70%;
      }
    }
  }
  @media (min-width: 1200px) {
    .head_title {
      h1 {
        max-width: 70%;
        font-size: 30px;
      }
    }
    .container_slider {
      .two_more_slides {
        display: block;
        display: flex;
      }
    }
  }
`;
export default function SliderKnowMore({idd}) {
  const [id, setId] = useState(0);
  const [servicesId, setServicesId] = useState(0);
  const nextId = () => {
    setId((id) => {
      if (id < contentArray.length - 1) {
        id += 1;
      } else {
        id = 0;
      }
      return id;
    });

  };

  const prevId = () => {
    setId((id) => {
      if (id !== 0) {
        id -= 1;
      } else {
        id = contentArray.length - 1;
      }
      return id;
    });
  };

  useEffect(() => {
    nextId(id);
  });

  useEffect(() => {
    prevId(id);
  });
  return (
    <StyledSliderKnow>
      <div className="head_title">
        <h1>
          En el oído se pueden originar condiciones y padecimientos{" "}
          <span>que repercuten en la calidad de vida de las personas:</span>
        </h1>
      </div>
      <div className="container_slider">
        <div className="button_left">
          <button onClick={prevId}>
            <img src={Left} alt="icon_button" />
          </button>
        </div>
        <StyledCardKnow>
          <h1>{contentArray[id].title.sintoma}</h1>
          <div className="enlace">
            <Link to={`/servicios/${contentArray[id].title.id}`} onClick={() => setServicesId(contentArray[id].title.id)} state={{data:contentArray[id].title}}>
              Saber más <img src={Mayor} alt="mayor_icon" />
            </Link>
          </div>
        </StyledCardKnow>
        <div className="two_more_slides">
          <StyledCardKnow>
            <h1>{contentArray[id].title2.sintoma}</h1>
            <div className="enlace">
            <Link to={`/servicios/${contentArray[id].title2.id}`} onClick={() => setServicesId(contentArray[id].title2.id)} state={{data:contentArray[id].title2}}>
              Saber más <img src={Mayor} alt="mayor_icon" />
            </Link>
            </div>
          </StyledCardKnow>
          <StyledCardKnow>
            <h1>{contentArray[id].title3.sintoma}</h1>
            <div className="enlace">
            <Link to={`/servicios/${contentArray[id].title3.id}`} onClick={() => setServicesId(contentArray[id].title2.id)} state={{data:contentArray[id].title2}}>
              Saber más <img src={Mayor} alt="mayor_icon" />
            </Link>
            </div>
          </StyledCardKnow>
        </div>
        <div className="button_rigth">
          <button onClick={nextId}>
            <img src={Rigth} alt="icon_button" />
          </button>
        </div>
      </div>
    </StyledSliderKnow>
  );
}

const contentArray = [
  {
    title: {sintoma: "Mareos", id: 4}, 
    title2: {sintoma: "Dejé de escuchar de repente", id: 1},
    title3: {sintoma: "Pido que les repitan las cosas", id: 1},
  },
  {
    title: {sintoma: "Al caminar, siento que me voy de lado", id: 4},
    title2: {sintoma: "Mi bebé no está aprendiendo a hablar", id: 9},
    title3: {sintoma: "Escucho un ruido constante", id: 3},
  },
  {
    title: {sintoma: "Pido que les repitan las cosas", id:1},
    title2: {sintoma: "Escucho, pero no entiendo", id: 1},
    title3: {sintoma: "Parálisis facial", id: 1},
  },
  {
    title: {sintoma: "Escucho un ruido constante", id: 3},
    title2: {sintoma: "Dejé de escuchar de repente", id: 1},
    title3: {sintoma: "Me cuesta trabajo participar en conversaciones", id: 1},
  },
  {
    title: {sintoma: "Parálisis facial", id: 1},
    title2: {sintoma: "Me cuesta trabajo participar en conversaciones", id: 1},
    title3: {sintoma: "Empiezo a olvidar las cosas", id: 1},
  },
  {
    title: {sintoma: "Me cuesta trabajo participar en conversaciones", id: 1},
    title2: {sintoma: "Parálisis facial", id: 1},
    title3: {sintoma:"A veces, finjo que escuché lo que me dijeron", id: 1},
  },
  {
    title: {sintoma: "Empiezo a olvidar las cosas", id: 1},
    title2: {sintoma: "Dejé de escuchar de repente", id: 1},
    title3: {sintoma: "Escucho, pero no entiendo", id: 1},
  },
  {
    title: {sintoma:"A veces, finjo que escuché lo que me dijeron", id: 1},
    title2:  {sintoma: "Escucho un ruido constante", id: 3},
    title3: {sintoma: "Mi bebé no está aprendiendo a hablar", id: 9},
  },
  {
    title: {sintoma: "Escucho, pero no entiendo", id: 1},
    title2: {sintoma: "Pido que les repitan las cosas", id:1},
    title3:  {sintoma: "Mareos", id: 4},
  },
  {
    title: {sintoma: "Mi bebé no está aprendiendo a hablar", id: 9},
    title2: {sintoma: "Al caminar, siento que me voy de lado", id: 4},
    title3: {sintoma: "Pido que les repitan las cosas", id:1},
  },
  {
    title: {sintoma: "Dejé de escuchar de repente", id: 1},
    title2: {sintoma: "Mareos", id: 4},
    title3: {sintoma: "Al caminar, siento que me voy de lado", id: 4},
  },
];
