import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Left from "../Assets/button_left.svg";
import Rigth from "../Assets/button_rigth.svg";

const StyledSlider = styled.div`
  background-color: var(--strong-blue);
  position: relative;
  .desktop {
    display: none;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .title_slide {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 56px;
    padding: 0 20px;
    color: white;
    h1 {
      font-family: "lato_bold";
      font-size: 24px;
      margin-bottom: 10px;
      /* max-width: 60%; */
    }
    p {
      font-family: "lato_regular";
      font-size: 16px;
      font-style: italic;
    }
  }
  .container_buttons {
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 20px;
    bottom: 20px;
    button {
      border: none;
      background: transparent;
      cursor: pointer;
    }
    .button_left {
      left: 20px;
    }
    .button_rigth {
      right: 20px;
    }
  }
  @media (min-width: 1200px) {
    .desktop {
      display: block;
    }
    .mobile {
      display: none;
    }
    .title_slide {
      flex-direction: column;
      top: 126px;
      left: 90px;
      color: white;
      h1 {
        font-family: "lato_bold";
        font-size: 30px;
        max-width: 60%;
      }
      p {
        font-family: "lato_regular";
        font-size: 25px;
      }
    }
    .container_buttons {
      bottom: 180px;
      img{
        width: 50px;
      }
      .button_left {
        left: 20px;
      }
      .button_rigth {
        right: 20px;
      }
    }
  }
  @media (min-width: 1400px) {
    .title_slide {
      flex-direction: column;
      top: 40%;
      left: 10%;
    }
  }
  @media (min-width: 1600px) {
    .title_slide {
      flex-direction: column;
      top: 40%;
    }
    .container_buttons {
      bottom: 40%;
    }
  }
`;

export default function SliderImages() {
  const [id, setId] = useState(0);
  const nextId = () => {
    setId((id) => {
      if (id < contentArray.length - 1) {
        id += 1;
      } else {
        id = 0;
      }
      return id;
    });
  };

  const prevId = () => {
    setId((id) => {
      if (id !== 0) {
        id -= 1;
      } else {
        id = contentArray.length - 1;
      }
      return id;
    });
  };

  useEffect(() => {
    nextId(id);
  });

  useEffect(() => {
    prevId(id);
  });

  return (
    <StyledSlider>
      <>
        <img className="mobile" src={contentArray[id].image} alt="image_test" />
        <img
          className="desktop"
          src={contentArray[id].imageDesktop}
          alt="image_test"
        />

        <div className="title_slide">
          <h1>{contentArray[id].title}</h1>
          <p>{contentArray[id].subtitle}</p>
        </div>

        <div className="container_buttons">
          <div className="button_left">
            <button onClick={prevId}>
              <img src={Left} alt="icon_button" />
            </button>
          </div>
          <div className="rigth">
            <button onClick={nextId}>
              <img src={Rigth} alt="icon_button" />
            </button>
          </div>
        </div>
      </>
    </StyledSlider>
  );
}

const contentArray = [
  {
    image: require("../Assets/portada_test.png").default,
    imageDesktop: require("../Assets/portada_1desktop.webp").default,
    title: "Instituto Mexicano de Otología y Neurotología",
    subtitle: "Cuidando de tu salud auditiva",
  },
  {
    image: require("../Assets/portada_2.webp").default,
    imageDesktop: require("../Assets/portada_2desktop.webp").default,
    title: "Instituto Mexicano de Otología y Neurotología",
    subtitle: "Cuidando de tu salud auditiva",
  },
  {
    image: require("../Assets/portada_test.png").default,
    imageDesktop: require("../Assets/portada_3desktop.webp").default,
    title: "Instituto Mexicano de Otología y Neurotología",
    subtitle: "Cuidando de tu salud auditiva",
  },
];
