import React from "react";
import HeaderBlueGray from "../utils/HeaderBlueGray";
import Graduate from "../Assets/graduate_purple.svg";
import Scream from "../Assets/scream.svg";
import Back from "../Assets/back_valores.webp";
import Mayor from "../Assets/mayor_que.svg";
import styled from "styled-components";

const StyledFormation = styled.div`
  .courses {
    padding: 0 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .course_info {
      width: 300px;
      display: flex;
      align-items: center;
      :first-child {
        margin-bottom: 40px;
      }
      img {
        width: 30px;
        margin-right: 15px;
      }
      p {
        font-family: "lato_bold";
        color: var(--strong-blue);
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .contact {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .top-center {
      position: absolute;
      top: 10%;
      left: 50%;
      transform: translate(-50%, 0%);
      .enlace {
        display: flex;
        a{
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--white);
            border-radius: 16px;
            border: 1px solid var(--strong-blue);
            font-family: "lato_regular";
            color: var(--strong-blue);
            font-size: 18px;
            padding: 5px;
            width: 300px;
        }
      }
    }
  }
  @media (min-width: 1200px) {
    .test {
      margin-bottom: 100px;
      height: 100%;
    }
    .top-center {
      top: 0%;
      .enlace{
        margin-top: 50px;
        a{
            width: 400px;
            height: 62px;
            font-size: 22px;
        }
      }
    }
  }
`;
export default function FormationImon() {
  const subtitle =
    "Conoce nuestros programas para profesionales de la salud que buscan especializarse y profundizar en temas relacionados con la audición:";

  return (
    <StyledFormation>
      <HeaderBlueGray title={"Formación IMON"} subtitle={subtitle} />
      <div className="courses">
        <div className="course_info">
          <img src={Graduate} alt="icon" />
          <p>Cursos de Posgrado de Alta Especialidad en Medicina (CPAEM)</p>
        </div>
        <div className="course_info">
          <img src={Scream} alt="icon" />
          <p>Método auditivo-verbal</p>
        </div>
      </div>

      <div className="contact">
        <img className="test" src={Back} alt="image_back" />
        <div className="top-center">
          <div className="enlace">
            <a href="/formacion">Conocer más información <img src={Mayor} alt="mayor_icon" /></a>
          </div>
        </div>
      </div>
    </StyledFormation>
  );
}
