import React, {useState, useEffect} from "react";
import Layout from "../Components/Layout/index";
import styled from "styled-components";
import Map from "../Components/Map";
import Reviews from "../Components/Reviews";
import TalkingWith from "../Components/TalkingWith";
import SliderImages from "../Components/SliderImages";
import FormationImon from "../Components/FormationImon";
import SliderKnowMore from "../Components/SliderKnowMore";
import ProductsAndServices from "../Components/ProductsAndServices";
const StyledIndex = styled.div``;

const Index = () =>  {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);
  return hasMounted ?(
    <StyledIndex>
      <Layout>
        <SliderImages />
        <SliderKnowMore />
        <ProductsAndServices />
        <FormationImon />
        <TalkingWith />
        <Reviews />
        <Map />
      </Layout>
    </StyledIndex>
  ): null
}

export default Index