import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

const StyledCardService = styled.div`
  box-shadow: 0px 8px 28px -6px rgba(118, 121, 114, 0.2),
    0px 18px 88px -4px rgba(109, 118, 117, 0.2);
  width: 145px;
  border-radius: 8px;
  height: 170px;
  display: flex;
  flex-direction: column;
  margin: 5px;
  .image_top {
    padding: 35px;
    display: flex;
    justify-content: center;
    img {
      width: 60px;
      height: 50px;
    }
  }
  hr {
    /* margin-top: 30px; */
    border: 0.5px solid var(--strong-blue);
  }
  .text {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    text-align: center;
    p {
      font-size: 12px;
      font-family: "lato_bold";
      color: var(--strong-blue);
      font-weight: 600;
    }
  }
  @media (min-width: 1200px) {
    width: 300px;
    height: 205px;
    margin: 15px;
    .image_top {
      padding: 30px;
      img {
        width: 100px;
        height: 70px;
      }
    }
    hr {
      border: 1px solid var(--strong-blue);
    }
    .text {
      margin-top: 20px;
      justify-content: flex-start;
      text-align: left;
      p {
        font-size: 18px;
      }
    }
  }
`;

export default function CardService(props) {
  const { image, title, hash } = props;
  const url = hash ? `/servicios#${hash}` : "/servicios";

  return (
    <StyledCardService>
      <Link to={url}>
        <div className="image_top">
          <img src={image} alt="" />
        </div>
        <hr />
        <div className="text">
          <p>{title}</p>
        </div>
      </Link>
    </StyledCardService>
  );
}
