import React from "react";
import YT from "../Assets/yt.webp";
import styled from "styled-components";
import VideoPurple from "../utils/VideoPurple";
const StyledTalking = styled.div`
  background-color: var(--strong-blue);
  padding: 30px 16px 50px;
  .title_talking {
    display: flex;
    margin-bottom: 50px;
    img {
      width: 45px;
      margin-right: 15px;
    }
    h1 {
      font-family: "lato_bold";
      font-size: 24px;
      color: white;
      font-weight: 500;
    }
  }
  .texts {
    p {
      font-family: "lato_regular";
      font-size: 20px;
      margin-bottom: 30px;
      color: white;
      :last-child {
        margin-bottom: 80px;
      }
    }
  }
  .video_purple {
    margin: 0 auto !important;
    width: 330px !important;
    iframe {
      width: 330px !important;
    }
  }
  @media (min-width: 500px) {
    .video_purple {
    margin: 0 auto !important;
    width: 500px !important;
    iframe {
      width: 500px !important;
    }
  }
  }
  @media (min-width: 1200px) {
    display: flex;
    flex-direction: row-reverse;
    padding: 0px 0px 7px;
    .container_title {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 80px;
      margin-left: 00px;
    }
  }
 
`;
export default function TalkingWith() {
  return (
    <StyledTalking>
      <div className="container_title">
        <div className="title_talking">
          <img src={YT} alt="" />
          <h1>Platicando con</h1>
        </div>
        <div className="texts">
          <p>
            Con el fin de combatir la desinformación, creamos nuestro canal
            “IMON Platicando con…”.
          </p>
          <p>
            A través de pláticas semanales por Zoom con profesionales de la
            salud y otros campos, resolvemos dudas y compartimos información
            confiable.
          </p>
        </div>
      </div>
      <div>
        <VideoPurple
          videoUrl={"https://www.youtube.com/embed/xQ--2Lc2il0"}
          titleVideo={"Platicando con otorrinos sobre coronavirus"}
        />
      </div>
    </StyledTalking>
  );
}
