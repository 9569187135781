import React from "react";
import styled from "styled-components";
import CardService from "../utils/CardService";
const StyledProducts = styled.div`
  background-color: var(--gray-ligth);
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 70px;

  h1 {
    font-family: "lato_bold";
    font-size: 20px;
    margin: 50px 0;
    color: var(--strong-blue);
  }
  .container_products {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  @media (min-width: 1200px) {
    h1 {
      font-size: 26px;
    }
  }
`;
export default function ProductsAndServices() {
  // const [id, setId] = useState(0);
  return (
    <StyledProducts>
      <h1>Servicios</h1>
      <div className="container_products">
        {productsArray.map((e, idx) => {
          return (
            <CardService
              key={idx}
              image={e.image}
              title={e.title}
              hash={e.hash}
            />
          );
        })}
      </div>
    </StyledProducts>
  );
}

const productsArray = [
  {
    image: require("../Assets/icon_oido.svg").default,
    title: "Implante Coclear",
    hash: "implante_coclear",
  },
  {
    image: require("../Assets/icon_cabeza.svg").default,
    title: "Vértigo",
    id: 4,
    hash: "vertigo",
  },
  {
    image: require("../Assets/icon_acufeno.svg").default,
    title: "Acúfeno (tinnitus)",
    id: 3,
    hash: "acufeno",
  },
  {
    image: require("../Assets/icon_otoesclerosis.svg").default,
    title: "Otoesclerosis",
    id: 6,
    hash: "otoesclerosis",
  },
  {
    image: require("../Assets/icon_cirugia.svg").default,
    title: "Cirugía de oído",
    id: 7,
    hash: "cirugia",
  },
  {
    image: require("../Assets/icon_imon_products.svg").default,
    title: "Centro auditivo IMON",
    id: 1,
    hash: "centro_auditivo",
  },
  {
    image: require("../Assets/icon_equilibrio.svg").default,
    title: "Estudios funcionales de audición y equilibrio",
    id: 2,
    hash: "estudios_funcionales",
  },
  {
    image: require("../Assets/icon_grandma.svg").default,
    title: "Tercera edad",
    id: 5,
    hash: "tercera_edad",
  },
  {
    image: require("../Assets/icon_programs.svg").default,
    title: "Nuestros programas de apoyo",
    id: 8,
    hash: "programas_apoyo",
  },
  {
    image: require("../Assets/icon_peluche.svg").default,
    title: "Infancia y niñez",
    id: 9,
    hash: "infancia_ninez",
  },
];
