import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Left from "../Assets/button_left.svg";
import Rigth from "../Assets/button_rigth.svg";
const StyledReviews = styled.div`
  background-color: var(--gray-ligth);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  padding-bottom: 100px;
  h1 {
    font-family: "lato_bold";
    color: var(--strong-blue);
    margin: 60px 0;
  }
  .container_white {
    background-color: var(--white);
    box-shadow: 0px 8px 28px -6px rgba(118, 121, 114, 0.8),
      0px 18px 88px -4px rgba(109, 118, 117, 0.8);
    width: 100%;
    /* min-height: 150px;
    max-height: 380px; */
    height: 350px;
    width: 300px;
    /* min-width: 300px;
    max-width: 1300px; */
    border-radius: 8px;
    padding: 25px;
    span {
      display: flex;
      margin-top: 20px;
      font-size: 20px;
      font-family: "lato_bold";
      color: var(--strong-blue);
      font-weight: 600;
    }
    p {
      font-family: "lato_regular";
      font-size: 16px;
      color: var(--gray-strong);
    }
    .button_left {
      position: absolute;
      display: flex;
      justify-content: space-between;
      margin-top: -180px;
      left: 0;
      padding-left: 20px;
      button {
        border: none;
        background: transparent;
        cursor: pointer;
      }
    }
    .rigth {
      position: absolute;
      display: flex;
      justify-content: space-between;
      margin-top: -180px;
      right: 0;
      padding-right: 20px;
      button {
        border: none;
        background: transparent;
        cursor: pointer;
      }
    }
  }
  @media (min-width: 320px) {
    .container_white {
      height: 400px;
      span {
        margin-top: 20px;
      }
      .button_left {
        left: -5%;
        max-width: 300px;
      }
      .rigth {
        right: -5%;
      }
    }
  }
  @media (min-width: 500px) {
    .container_white {
      span {
        margin-top: 20px;
      }
      .button_left {
        left: 10%;
        max-width: 300px;
      }
      .rigth {
        right: 10%;
      }
    }
  }
  @media (min-width: 900px) {
    .container_white {
      width: 400px;
      height: 700px;
      padding: 30px;
      p {
        font-size: 26px;
      }
      span {
        margin-top: 80px;
        font-size: 26px;
      }
      .button_left {
        margin-top: -300px;
      }
      .rigth {
        margin-top: -300px;
      }
    }
  }

  @media (min-width: 1200px) {
    .container_white {
      width: 1000px;
      height: 400px;
      padding: 30px;
      img{
        width: 50px;
      }
      p {
        font-size: 26px;
      }
      span {
        margin-top: 80px;
        font-size: 26px;
      }
      .button_left {
        margin-top: -150px;
        left: 0%;
      }
      .rigth {
        margin-top: -150px;
        right: 0%;
      }
    }
  }
  @media (min-width: 1300px) {
    .container_white {
      .button_left {
        left: 5% !important;
      }
      .rigth {
        right: 5% !important;
      }
    }
  }
  @media (min-width: 1400px) {
    .container_white {
      .button_left {
        left: 6% !important;
      }
      .rigth {
        right: 6% !important;
      }
    }
  }
  @media (min-width: 1600px) {
    .container_white {
      .button_left {
        left: 7% !important;
      }
      .rigth {
        right: 7% !important;
      }
    }
  }
  @media (min-width: 1800px) {
    .container_white {
      .button_left {
        left: 10% !important;
      }
      .rigth {
        right: 10% !important;
      }
    }
  }
`;

export default function Reviews() {
  const [id, setId] = useState(0);
  const nextId = () => {
    setId((id) => {
      if (id < contentArray.length - 1) {
        id += 1;
      } else {
        id = 0;
      }
      return id;
    });
  };

  const prevId = () => {
    setId((id) => {
      if (id !== 0) {
        id -= 1;
      } else {
        id = contentArray.length - 1;
      }
      return id;
    });
  };

  useEffect(() => {
    nextId(id);
  });

  useEffect(() => {
    prevId(id);
  });

  return (
    <StyledReviews>
      <h1>Reseñas</h1>
      <div>
        <div className="container_white">
          <p>
            <p>{contentArray[id].paragraph}</p>
          </p>
          <span>{contentArray[id].autor}</span>
          <div className="button_left">
            <button onClick={prevId}>
              <img src={Left} alt="icon_button" />
            </button>
          </div>
          <div className="rigth">
            <button onClick={nextId}>
              <img src={Rigth} alt="icon_button" />
            </button>
          </div>
        </div>
      </div>
    </StyledReviews>
  );
}

const contentArray = [
  {
    paragraph:
      "Tengo 55 años. Perdí la audición cuando me dio COVID. Mi recuperación fue lenta y tarde en darme cuenta que el no escuchar cambiaría mi vida. La sordera, junto con la difícil recuperación hizo que me meta en un mundo interior donde me hacían falta todos. Me sentía triste sin motivo. Hacerme introspectiva fue un cambio positivo, pero lo mejor fue ir al centro auditivo IMON a ponerme auxiliares auditivos. Oír le devolvió color a mi vida. Cuando me conecto vuelvo a ser la misma de antes.",
    autor: "ETELE BORNIK",
  },
  {
    paragraph:
      "Tengo 65 años. Ahora entiendo que estar sana, incluye ponerme los auxiliares auditivos en mis oídos todos los días. Dejé de oír desde hace años, pero me resistía a ponerme aparatos porque yo quería oír sin ellos. Negaba mi condición de sorda y me enojaba cuando mis hijos y mis amigas me repetían de mala gana. Estar cerca de los míos se hacía difícil para todos. Con los aparatos me gusta convivir; me siento integrada y segura.",
    autor: "PATRICIA ALCALÁ",
  },
  {
    paragraph:
      "Tengo 37 años. Desde niño escuchaba fuertísimo la música para desahogarme. Todos se reían y yo no agarraba el chiste. Estaba siempre afuera de la fiesta, especialmente cuando nos juntábamos varios. Se me infecto un oído y el Doctor recomendó que me hiciera una audiometría. La sorpresa de mi baja audición fue grande. Desde que uso los aparatos soy otro. Extrañaba las conversaciones por teléfono. Ahora me doy cuenta lo difícil que era escuchar a los demás. No oír te aleja de los otros; nadie tiene tiempo de repetir.",
    autor: "SAÚL GOLDSTEIN",
  },
];
